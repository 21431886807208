/*samll portrait***************************************************************/
:root {
  --auction-viewer__margin-bottom: .5rem;
  --bid-selector__icon-bar__grid-row: 2 / 4;
  --bid-selector__key-pad__grid-column: 1 / 2;
  --bid-selector__margin: 0%;
  --bid-selector__grid-row-gap: 1rem;
  --bid-selector__container-width: 100vw;
  --bridge-game__container-grid-column: 1 / 2;
  --bridge-game__container-grid-row: 2 / 3;
  --bridge-game__container-height: 96vh;
  --player-height: 30vh;
  --bridgeblvd-title__wrap: wrap;
  --deals-menu__container-margin: 1vw;
  --deals-menu__container-height: 98vh;
  --deals-menu__container-width: 98vw;
  --drop-down__width: 100vw;
  --h1_font-size: 2rem;
  --hand_img-width: 16vw;
  --hand__li-margin-width: 0 1rem;
  --icon-bid-value__margin-left: .5vw;
  --icon-bid-value__margin-bottom: .4vh;
  --landing-page__card-height: 86vh;
  --landing-page__card__margin: 0;
  --landing-page__card-width: 94vw;
  --landing-page__practice-options-margin: 15% auto;
  --landing-page__title-margin-left: 0;
  --main-column-width: 100vw;
  --main-component__container-border: none;
  --main-layout-column-width: 100vw;
  --main-layout-rows: 6vh 1fr 1fr;
  --modal-dialog-margin: 2vh auto 0;
  --modal-dialog-width: 98vw;
  --nav-bar__container-margin-top: 0;
  --nav-bar__container-columns: 1 / 3;
  --nav-bar__icon-container-padding-right: .5rem;
  --playing-card__width: 60;
  --secondary-column-width: 400px;
  --setting-layout__columns: 1fr;
  --setting-layout__grid-gap: .1rem;
  --settings-viewer-section__grid-column: 1;
  --status-viewer__container-grid-column: 1;
  --status-viewer__container-grid-row: 1;
  --trick-viewer__container-margin: 0 8vw;
}

:root, [data-theme="light"] {
  --club: #2F7935;
  --diamond: #fd5c17;
  --heart: #E60000;
  --spade: #1212A6;
  --nt: #E308F7;
  --bridge-button__active: var(--spade);
  --memo-color: #FFFF8A;
  --memo-text-color: #3B4252;
  --bid-icon-background-color: #FFE48A;
  --bid-icon-text-color: var(--memo-text-color);
  --bid-icon-hover-color: #FFDABF;
  --bid-icon-border-color: #3C0000;
  --icon-bg-color: rgba(250, 255, 255, 0);
  --bridge-felt: hsla(92, 48%, 70%, 0.96); /*#CAEDAC; #B1C89D;*/
  --component-color: #FFFFFF;
  --secondary-component-color: #E6E6E6;/*#F2F2F2;*/
  --component-container-color: #C0C0C0;
  --player-color: #97C8FF;/*#90cdee #81caf0 #9FC9DF;*/
  --component-border-color: var(--player-color);/*#9FC9DF*/
  --nav-bar__bg: linear-gradient(to top, var(--component-color), 80%, var(--player-color));
  --modal-dialog-color: var(--component-container-color);/* #F9FFFF*/
  --error-background-color: #EBCB8B;
  --error-color: #BF616A;
  --light-border-color: #E1E1E1;
  --main-bg-color: var(--bridge-felt);/*#F8D2A1#FCFEFF;#EFF1F6;*/
  --dlg-title-bg-color: #6495ED;/*#4481A2;*/
  --dlg-title-bg: linear-gradient(to top, #E3FFFF, 70%, var(--dlg-title-bg-color));
  --main-text-color: #222939;/*3B4252;*/
  --highlight-text: white;
  --bridge-active: #CEEEFF;
  --tab-button-border-color: #1d4568; /*#2e3b46;*/
  --shadow1: rgba(0, 0, 0, 0.30);
  --shadow2: rgba(0, 0, 0, 0.22);
}

:root, [data-theme="dark"] {
  --club: hsla(125, 52%, 45%, 1);
  --diamond: hsla(29, 54%, 56%, 1);
  --heart: hsla(356, 68%, 68%, 1);
  --spade: hsla(208, 56%, 60%, 1);
  --nt: hsla(295, 64%, 61%, 1);
  --bridge-button__active: hsla(212, 70%, 50%, 1);
  --memo-color: hsla(190, 100%, 80%, 1); /*hsla(195, 60%, 62%, 1);*/
  --memo-text-color: #000006;
  --icon-bg-color: #A2A3A3;
  --bridge-active: var(--spade);
  --bid-icon-background-color: hsla(240, 40%, 42%, 1);
  --bid-icon-text-color: #F9FFFF;
  --bid-icon-hover-color: hsla(254, 72%, 59%, 1);
  --bid-icon-border-color: #535399;
  --bridge-felt: #12212E;
  --component-color: #272727;
  --secondary-component-color: #4D4D4D;
  --component-container-color: #343434;
  --component-border-color: #3C3D3E;
  --nav-bar__bg: linear-gradient(to top, var(--component-color), 10%, var(--secondary-component-color));
  --modal-dialog-color: #2F3031;
  --error-background-color: var(--heart);
  --error-color: var(--memo-text-color);
  --light-border-color: #6d7073;
  --main-bg-color: var(--bridge-felt);
  --player-color: #445B8D;/*#3D566D;*/
  --dlg-title-bg-color: #0A233A;/*#222324;*/
  --dlg-title-bg: linear-gradient(to top, black, 10%, var(--dlg-title-bg-color));
  --main-text-color: #C7CDD3;/*#B8BEC4;*/
  --highlight-text: #EAF0F6;
  --tab-button-border-color: #1d4568;
  --shadow1: rgba(0, 0, 0, 0.30);
  --shadow2: rgba(0, 0, 0, 0.22);
}

/*tablet portrait**************************************************************/
@media (min-width: 768px) {
  :root, [data-theme="light"] {
    --main-bg-color: #FFEBBA;
  }
  :root, [data-theme="dark"] {
    --main-bg-color: #222324;
  }
  :root {
    --auction-viewer__margin-bottom: 1rem;
    --bid-selector__container-width: 60vw;
    --bridge-game__container-grid-column: 2 / 3;
    --bridge-game__container-grid-row: 2 / 3;
    --bridge-game__container-height: 70vh;
    --player-height: 23vh;
    --deals-menu__container-margin: 2vh 12vw;
    --deals-menu__container-height: 68vh;
    --deals-menu__container-width: 60vw;
    --drop-down__width: var(--secondary-column-width);
    --landing-page__card-height: 64vh;
    --landing-page__card__margin: 2vh auto;
    --landing-page__card-width: calc(var(--landing-page__card-height) * .6);
    --h1_font-size: 2.8rem;
    --main-component__container-border: thick double;
    --main-column-width: 70vw;
    --main-layout-column-width: var(--main-column-width);
    --nav-bar__container-columns: 2 / 3;
    --nav-bar__icon-container-padding-right: 2rem;
    --setting-layout__columns: 2fr 4fr 2fr;
    --setting-layout__grid-gap: 1rem;
    --settings-viewer-section__grid-column: 2 / 3;
  }
}

/*largr portrait***************************************************************/
@media (min-width: 992px) {
  :root {
    --auction-viewer__margin-bottom: 0;
    --bridgeblvd-title__wrap: nowrap;
    --deals-menu__container-height: 92vh;
    --deals-menu__container-width: 44vw;
    --landing-page__card-height: 44vh;
    --landing-page__card-width: calc(var(--landing-page__card-height) * .62);
    --main-column-width: 46vw;
    --main-layout-column-width: calc(var(--main-column-width) + var(--secondary-column-width) + 1vw);
    --modal-dialog-margin: 10vh auto 0;
  }
}

/*largest portrait*************************************************************/
@media (min-width: 1200px) {
  :root {
    --landing-page__card-height: 54vh;
    --landing-page__card-width: calc(var(--landing-page__card-height) * .6);
  }
}

.main-layout {
  display: grid;
  grid-template-columns: auto var(--main-layout-column-width) auto;
  grid-template-rows: auto 1fr auto;
  justify-items: center;
  grid-row-gap: .5vh;
}
.game-components__container {
  display: grid;
  grid-auto-rows: auto;
  grid-column-gap: 4rem;
}

/* small landscape*************************************************************/
@media screen
and (min-width: 320px)
and (max-width: 813px)
and (max-height: 414px)
and (orientation: landscape) {
  :root, [data-theme="light"] {
    --main-bg-color: var(--bridge-felt);
  }
  :root, [data-theme="dark"] {
    --main-bg-color: var(--bridge-felt);
  }
  :root {
    --bridge-game__container-height: 180vh;
    --player-height: 60vh;
    --deals-menu__container-margin: 0 10vw;
    --deals-menu__container-height: 200vh;
    --deals-menu__container-width: 80vw;
    --landing-page__practice-options-margin: 0 auto 0 2vw;
    --landing-page__title-margin-left: 18vw;
    --landing-page__card-height: 77vh;
    --landing-page__card-width: calc(var(--landing-page__card-height) * 2.6);
    --modal-dialog-width: 90vw;
    --modal-dialog-margin: 1vh 4vw;
  }
}

@media only screen
and (min-width: 768px)
and (min-height: 415px)
and (orientation: landscape) {
  :root {
    --bid-selector__container-width: 32vw;
    --bridge-game__container-height: 86vh;
    --player-height: 27vh;
    --bid-selector__icon-bar__grid-row: 2;
    --bid-selector__key-pad__grid-column: 1 / 3;
    --bid-selector__margin: 2%;
    --bid-selector__grid-row-gap: 2rem;
    --bridge-game__container-grid-column: 2 / 3;
    --bridge-game__container-grid-row: 2 / 3;
    --deals-menu__container-margin: 2vh 10vw;
    --h1_font-size: 3.6rem;
    --hand_img-width: 8vw;
    --hand__li-margin-width: 0 .2rem;
    --icon-bid-value__margin-left: .8vw;
    --icon-bid-value__margin-bottom: .8vh;
    --landing-page__card-height: 76vh;
    --landing-page__card-width: calc(var(--landing-page__card-height) * .67);
    --main-layout-rows: 1fr 14fr 1fr;
    --modal-dialog-width: 50vw;
    --nav-bar__container-margin-top: 1rem;
    --playing-card__width: 60;
    --status-viewer__container-grid-column: 2;
    --status-viewer__container-grid-row: 2;
    --trick-viewer__container-margin: inherit;
  }

  .main-layout {
    display: grid;
    grid-template-columns: auto var(--main-layout-column-width) auto;
    grid-template-rows: var(--main-layout-rows);
    grid-row-gap: 1vh;
    width: 100vw;
    height: 100vh;
  }

  .game-components__container {
    display: grid;
    grid-template-columns: var(--main-column-width) var(--secondary-column-width);
    grid-auto-rows: auto;
    grid-column-gap: 4rem;
  }
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--main-bg-color);
  color: var(--main-text-color)
}

img {
  display: block;
}

input[type="text"]:focus {
  outline: 2px solid var(--club);
}

table {
  margin: auto;
  border-collapse: collapse;
  background-color: var(--component-color);
}

table td.centered {
  text-align: center;
}

th {
  font-size: 90%;
  font-weight: 500;
}

pre {
  overflow: auto;
}

ul {
  list-style-type: none;
}
code {
  font-family: monospace, monospace;
  padding: .2rem .5rem;
  margin: 0 .2rem;
  font-size: 90%;
  white-space: nowrap;
}

pre > code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}

h1 {
  font-size: var(--h1_font-size);
}
h2 {
  font-size: 3.3rem;
}
h6 {
  font-weight: bold;
}

th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid var(--light-border-color);
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

article {
  padding: 3rem;
  -webkit-columns: 2 400px;
  -moz-columns: 2 400px;
  columns: 2 400px;
}

.span:focus-within {
  color: var(--memo-text-color);
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.scroll-lock {
     overflow:hidden;
}

.bridgeblvd-title {
  display: flex;
  flex-wrap: var(--bridgeblvd-title__wrap);
  font-family: 'Poller One', cursive;
  font-weight: bold;
}
.bridgeblvd-subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: var(--main-text-color);
  letter-spacing: normal;
}

.button-center_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-center {
  margin: .5rem;
  white-space: normal;
  box-shadow: 0 6px 18px -2px var(--shadow1);
}

.bridge-button {
  display: inline-block;
  font-size: 3rem;
  font-family: 'Inter', sans-serif;
  color: var(--main-text-color);
  text-align: center;
  background: none;
  text-transform: none;
}

.bridge-button:hover,
.bridge-button:focus {
  color: var(--highlight-text);
  moz-border-radius: .5rem;
  border-radius: .5rem;
  border: 1px;
  background-color: var(--bridge-button__active);
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.54), 0 17px 50px 0 rgba(0,0,0,0.39);
  transition: .5s ease;
}

select:hover,
select:focus,
select:active {
  border: 1px solid var(--bridge-active);
}

a {
  text-decoration: none;
  color: var(--club);
}

input[type="submit"]:hover,
input[type="submit"]:active,
input[type="submit"]:focus,
a:hover,
a:active,
a:focus {
  moz-border-radius: .75rem;
  border-radius: .75rem;
  border: 1px;
  background-color: var(--bridge-active);
  color: var(--memo-text-color);
}

.show {
  opacity: 1;
  visibility: visible;
}

.hide {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.box-shadow {
  box-shadow: 0 19px 38px 12px var(--shadow1);
}

#root {
  min-height: inherit;
}


#game-components {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  grid-column-gap: 1vw;
}

#bridge-game__container {
  grid-row: var(--bridge-game__container-grid-row);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 33% 1fr 1fr;
  z-index: 10;
  height: var(--bridge-game__container-height);
  min-height: 80vh;
  font-size: var(--bridge-game__font-size);
}

.splash-page-layout {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  grid-template-rows: 1fr 8fr;
}

.status-viewer__container {
  grid-column: var(--status-viewer__container-grid-column);
  grid-row: var(--status-viewer__container-grid-row);
  position: relative;
  height: auto;
  margin: 0;
}

.status-viewer__content {
  position: absolute;
  width: 100%;
}

.heightZero {
  height: 0;
}

.main-layout__container {
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  width: 100%;
}

.tool-bar {
  background-color: var(--dlg-title-bg-color);
  color: var(--spade);
}

.felt__container {
  background: var(--bridge-felt);
  border-radius: 1rem;
}

.main-component__container {
  moz-border-radius: 1rem;
  border-radius: 1rem;
  height: 98%;
  border: var(--main-component__container-border);
  border-color: var(--component-border-color);
  background-color: var(--component-color);
  box-shadow: 0 19px 38px var(--shadow1), 0 15px 12px var(--shadow2);
  overflow: hidden;
  font-size: var(--bridge-game__font-size);
}

.focus {
  color: var(--memo-text-color);
  background-color: var(--memo-color);
}

.focus-cell-border {
  border-left: thin solid var(--memo-color);
  border-right: thin solid var(--memo-color);
}

/* bid guide ******************************************************************/
#bid-guide-viewer {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  height: max-content;
  font-size: var(--bridge-game__font-size);
}

#bid-guide-viewer__components {
  padding: 1rem;
  background-color: var(--component-container-color);
}

.bid-guide-panel {
  display: flex;
  justify-content: space-around;
}

#bid-guide-viewer__auction {
  /*grid-column: 1 / 3;
  grid-row: 1 / 2;*/
}

#bid-guide-viewer__player {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

#bid-guide-viewer__options-section {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#bid-guide-viewer__options-tabs {
  margin-bottom: .2rem;
  border-bottom: 1px solid var(--tab-button-border-color)
}

#bid-guide-viewer__options-tabs ul {
  display: flex;
  margin-bottom: 0;
}

#bid-guide-viewer__options-tabs .tab-button {
  margin: 0;
  padding: .1rem 1.2rem;
  border-bottom: 0;
}

/* deal name title ************************************************************/
#deal-name-heading {
  grid-column: 2 / 3;
  grid-row: 2;
  height: 100%;
  margin-left: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#deal-name-heading h5 {
  margin-bottom: 0;
}

/* card *********************************************************************/
.card-img {
  object-fit: scale-down;
  /*
  width: 100%;
  box-shadow: 5px 8px 5px 0 var(--shadow1);
  moz-border-radius: .5rem;*/
  border-radius: .5rem;
}

.card-front {
  display: flex;
  background-color: var(--highlight-text);
  padding: .3rem;
  width: 100%;
  height: 100%;
}

.card-value {
  text-align: justify;
}

.card-value__font {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 120%;
}

.card-strain__font {
  font-family: monospace, monospace;
  font-size: 1em;
}

.pts {
  opacity: 1;
  font-weight: 500;
  margin-top: .5rem;
  padding-left: 1rem;
}

.bridge-game__player-seat {
  moz-border-radius: .75rem;
  border-radius: .75rem;
  background-color: var(--player-color);
  padding: 1vh;
  height: max-content;
  min-height: var(--player-height);
}

#bridge-game__properties {
  grid-column: 5 / 7;
  grid-row: 1 / 3;
  padding: 1rem;
}

#bridge-game__trick-score {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  padding: 1rem;
}

#bridge-game__deal-next {
  grid-column: 1 / 3;
  grid-row: 4 / 6;
}

#bridge-game__play-next-card {
  grid-column: 5 / 7;
  grid-row: 4 / 6;
  display: flex;
  justify-content: flex-end;
}

#bridge-game__play-next-card button {
  height: auto;
  width: min-content;
  padding: 1vh;
}

#bridge-game__play-next-card span {
  display: block;
}

.player-West {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
}

.player-North {
  grid-column: 3 / 5;
  grid-row: 1 / 3;
}

.player-East {
  grid-column: 5 / 7;
  grid-row: 3 / 4;
}

.player-South {
  grid-column: 3 / 5;
  grid-row: 4 / 6;
}

.trick-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(8, 1fr);
}

#bridge-game__playing-board {
  grid-column: 3 / 5;
  grid-row: 3 / 4;
  /*max-height: 190px;*/
  margin: 2vh;
}

#card-West {
  grid-column: 2 / 6;
  grid-row: 3 / 8;
}

#card-North {
  grid-column: 3 / 7;
  grid-row: 1 / 6;
}

#card-East {
  grid-column: 4 / 8;
  grid-row: 2 / 7;
}

#card-South {
  grid-column: 3 / 7;
  grid-row: 4 / 9;
}

.hand {
  background-color: var(--component-color);
  width: 100%;
  padding: .5rem 0;
}

.hand-border-west {
  border-top-right-radius: 1rem 1.5rem;
  border-bottom-right-radius: 1rem 1.5rem;
}

.hand-border-north {
  border-bottom-left-radius: 1rem 1.5rem;
  border-bottom-right-radius: 1rem 1.5rem;
}

.hand-border-east {
  border-top-left-radius: 1rem 1.5rem;
  border-bottom-left-radius: 1rem 1.5rem;
}

.hand-border-south {
  border-top-left-radius: 1rem 1.5rem;
  border-top-right-radius: 1rem 1.5rem;
}

.highlight-shadow {
  box-shadow: 0 0 6px 4px var(--memo-color);
}

.hand img {
  display:block;
  margin:auto;
  background-color: whitesmoke;
  width: var(--hand_img-width);
  border-radius: 50%;
  /*filter: invert(78%);*/
}

.hand ul {
  flex-flow: row wrap;
  align-content: flex-start;
  padding: 0;
  margin: 0;
}

.hand li {
  margin: var(--hand__li-margin-width);
  border-top: 1px solid grey;
  border-radius: 1rem;
  word-break: break-all;
}

.club {
  color: var(--club);
}

.diamond {
  color: var(--diamond);
}

.heart {
  color: var(--heart);
}

.spade {
  color: var(--spade);
}

.nt {
  color: var(--nt)
}

/* next-deal-query ************************************************************/
#next-deal-query__container {
  height: auto;
  width: 80%;
  margin: auto;
}

.next-deal-query__message {
  height: 60%;
  margin: 10% 20%;
  font-size: 2.5rem;
  font-weight: bold;
}

/* tally-viewer ***************************************************************/
#tally-viewer__container {
  margin: auto;
  width: fit-content;
}

.tally-viewer__table {
  table-layout: fixed;
  margin: 4px;
}

.tally-viewer__table caption {
  color: var(--main-text-color);
  font-size: 2.5rem;
  font-weight: bold;
}

.tally-viewer__table th {
  padding: 4px 6px;
}

.tally-viewer__table td {
  padding: 4px 6px;
  text-align: right;
}

.tally-viewer__table th:first-child {
  white-space:nowrap;
  text-align: right;
}

.tally-viewer__table th:nth-child(2) {
  padding-left: 1rem;
  text-align: right;
  width: 200px;
}

.border-none {
  border-bottom: none;
}

#tally-viewer__btn-container {
  margin-top: 36px;
}

/* trick-viewer ***************************************************************/
#trick-viewer__container {
  height: auto;
  margin: var(--trick-viewer__container-margin);
}

#bridge-game__trick-viewer {
  height: inherit;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
}

#trick-viewer__winner {
  text-align: center;
  vertical-align: middle;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-family: sans-serif;
  font-size: 2.5rem;
}

#trick-viewer__trick-grid {
  background-color: var(--bridge-felt);
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  padding: 2rem;
}

#trick-viewer__btn-div {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

/* auction ********************************************************************/
#auction-viewer {
  grid-column: 1;
  grid-row: 1;
  margin-bottom: var(--auction-viewer__margin-bottom);
  z-index: 1;
}

#auction-view__container {
  height: auto;
  margin: 0;
}

.auction-table {
  width: 100%;
  border-radius: 1rem;
}

.auction-header__selected {
  border: thin solid var(--memo-color);
}

.auction-cell {
  text-align: center;
  cursor: pointer;
  padding: .7rem 1.2rem;
}

.auction-table td:first-child {
  padding-left: 1.2rem;
}

.auction-cell__bid-font {
  font-size: 125%;
}

.auction-bid-options {
  grid-column: 1 / span 4
}

.bid-display {
  border-radius: 20%;
  background-color: var(--component-color);
  color: var(--main-text-color);
  margin: auto;
  padding: 0 .5rem;
  width: fit-content;
}

.bid-table {
  margin: .5rem;
}

.bid-table caption {
  text-align: left;
  background-color: var(--player-color);
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  padding: 1rem 4rem;
  width: max-content;
  padding: 1rem 4rem;
  border-top-left-radius: 10% 40%;
  border-top-right-radius: 10% 40%;
}

.bid-table th {
  font-size: x-large;
  padding-left: 1rem;
}

.bid-table td {
  padding: 1rem;
  vertical-align: baseline;
  width: 20%;
}

.bid-options__section h3 {
  font-size: 2.8rem;
  margin: .5rem 1rem;
}

.bid-options {
  margin-top: 0;
  margin-left: 3rem;
  margin-right: 1rem;
}

dl.bid-options, dl.bid-options dl {
  counter-reset: option-counter;
}

.bid-options dt {
	counter-increment: option-counter;
}

.bid-options dt:before {
  display: flex;
  align-items: center;
  justify-content: center;
  content: counters(option-counter,".") " ";
  position: absolute;
  left: -2rem;
  top: 0;
  font: bold 3rem/1 Sans-Serif;
  background-color: var(--heart);
  color: white;
  width: 3rem;
  height: 100%;
  text-shadow: 0 1px 0 #424242;
}

.bid-options__sub-level-def dt:before {
  left: -2.4rem;
  width: 4rem;
  font: bold 2rem/1 Sans-Serif;
}

.bid-options dt {
  position: relative;
  font: 100% sans-serif;
}

.bid-options dd {
  margin: 0 0 .2rem 0;
}

.bid-options dt {
  padding-left: 2.4rem;
}

.bid-options dd {
  padding-left: 2.4rem;
  margin-left: 1rem;
}

.bid-options__selected {
  font-size: 150%;
}

.bid-options__selected-container {
  border: 2px solid var(--club);
  border-top: 0;
  padding: .5rem;
  margin: 0 0 0 -2.4rem;
}

.bid-options__title {
  background-color: var(--secondary-component-color);
  min-height: 3rem;
}

.bid-options__title div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .2rem;
}

.bid-options__sub-level-def {
  background-color: var(--component-color);
}

.bid-options__sub-level-list {
  margin: 0;
}

.bid-options__content {
  padding: .5rem;
}

/* promises *******************************************************************/
.info-container {
  color: var(--memo-text-color);
  margin: .5rem .2rem;
  background-color: var(--memo-color);
  overflow: auto;
  margin-top: .5rem;
  border-radius: .5rem;
  line-height: 1.5;
  min-height: 11vh;
}

.info-container__actions {
  display: flex;
  justify-content: space-between;
}

.info-container__actions h3 {
  margin-bottom: 0;
}

.info-container__title {
  padding: .25rem .5rem;
  font-size: 120%;
  font-weight: 500;
  max-width: 60%;
  color: darkblue;
}

.info-bar {
  float: right;
  padding: .25rem .25rem;
}

.info-bar button {
  font-family: monospace, serif;
  font-size: 2rem;
  width: 4rem;
  padding: 0px;
  border: solid var(--light-border-color);
  color: #112b46;
  text-align: center;
  margin-left: .1rem;
  margin-bottom: .1rem;
}

.info-bar button:hover,
.info-bar button:focus {
  color: var(--highlight-text);
  background-color: var(--bridge-button__active);
  border: solid var(--club);
  transition: .5s ease;
}

.info-container p {
  word-wrap: break-word;
  margin: .5rem;
  font-weight: 600;
}

/* bid-selector ********************************************************/
#bid-selector-viewer {
}

#bid-selector__container {
  height: auto;
  margin: 0;
  width: var(--bid-selector__container-width);
  background-color: var(--component-container-color);
}

#bid-selector {
  display: grid;
  grid-template-columns: 6.5fr 1fr;
  grid-template-rows: auto auto auto;
  margin: var(--bid-selector__margin);
  grid-row-gap: var(--bid-selector__grid-row-gap);
  padding: .3rem;
}

#bid-selector__player {
  grid-column: 1 / 3;
  grid-row: 1;
  background-color: var(--player-color);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

#bid-selector__grid {
  grid-column: 1 / 2;
  grid-row: 2;
  margin: 0 1rem;
}

#bid-selector__icon-bar {
  grid-column: 2;
  grid-row: var(--bid-selector__icon-bar__grid-row);
  display: flex;
  flex-direction: column;
}

#bid-selector__key-pad {
  grid-column: var(--bid-selector__key-pad__grid-column);
  grid-row: 3;
}

.bid-selector__key-pad__row {
  display: flex;
  justify-content: flex-start;
  margin-right: 1vw;
}

.icon-bid-side {
  font-size: 1.4rem;
  flex: 1;
  margin-bottom: .4rem;
  padding: 2rem 0;
  color: var(--bid-icon-text-color);
  background-color: var(--bid-icon-background-color);
  border: var(--bid-icon-border-color) .1rem solid;
}

.icon-bid-value {
  flex: 1;
  font-size: 1.6rem;
  margin-left: var(--icon-bid-value__margin-left);
  margin-bottom: var(--icon-bid-value__margin-bottom);
  color: var(--bid-icon-text-color);
  background-color: var(--bid-icon-background-color);
  border: var(--bid-icon-border-color) .1rem solid;
  text-align: right;
  padding: 0;
}

.icon-bid-side:focus,
.icon-bid-side:hover,
.icon-bid-value:focus,
.icon-bid-value:hover {
  background-color: var(--bid-icon-hover-color);
  border: var(--bid-icon-border-color) .1rem solid;
  color: var(--bid-icon-text-color);
  transition: .5s ease;
  outline: .2rem solid var(--club);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.isHidden {
  opacity: 0;
}

.isVisible {
  opacity: 1;
}

.isDisabled > a {
  color: currentColor;
  display: inline-block;
  pointer-events: none;
  text-decoration: none;
}

/* setting ********************************************************************/
.settings-layout {
  display: grid;
  grid-template-columns: var(--setting-layout__columns);
  grid-template-rows: auto auto;
  grid-gap: var(--setting-layout__grid-gap);
}

#settings-viewer-section {
  grid-column: var(--settings-viewer-section__grid-column);
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#settings-viewer__tabs {
  display: flex;
  margin-bottom: 0;
  border-bottom: 1px solid var(--tab-button-border-color);
}

#settings-viewer__tabs .tab-button {
  margin: 0;
  /*
  padding: .1rem 1.2rem;
  border-bottom: 0;*/
}

.settings-viewer__card {
  height: auto;
  overflow: visible;
  margin: .5rem 0;
  padding: 10px 10px;
}

.settings-viewer__sep {
  width: 100%;
  border-bottom: 1px solid var(--component-border-color);
  margin: .5rem;
}

.settings-viewer__editor {
  background-color: var(--component-container-color);
  margin: 1rem 0 .2rem 0;
  padding: .5rem ;
  border-radius: 1rem;
}

/* landing page ***************************************************************/
.landing-page-layout {
  display: grid;
  grid-template-columns: auto var(--main-column-width) auto;
  grid-template-rows: 66px auto;
  grid-gap: 1vh;
  width: 100vw;
  height: 100vh;
}

#landing-page__container {
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  height: 84vh;
}

.landing-page__card {
  margin: var(--landing-page__card__margin);
  border: thick double var(--player-color);
  display: flex;
  min-width: var(--landing-page__card-width);
  max-width: 98vw;
  height: var(--landing-page__card-height);
  width: var(--landing-page__card-width);
}

.landing-page__title {
  display: flex;
  color: var(--main-text-color);
  font-size: 3.5rem;
  margin-top: 1rem;
  margin-left: var(--landing-page__title-margin-left);
}

.landing-page__title-vert {
  width: 6rem;
  -webkit-writing-mode: vertical-rl;
  -moz-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: upright;
  -moz-text-orientation: upright;
  -ms-text-orientation: upright;
  text-orientation: upright;
  margin-top: 1rem;
}

.landing-page__title-vert span {
  margin-bottom: .5rem;
}

.landing-page__title-suits {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-left: 1rem solid var(--player-color);
  border-right: 1rem solid var(--player-color);
  font-family: monospace;
}

.landing-page__practice-options {
  margin: var(--landing-page__practice-options-margin);
}

/* four-zero-four *************************************************************/
.four-zero-four {
  grid-column:  2/ 3;
  grid-row: 2 / 3;
  width: auto;
  margin: 10%;
}

/* nav-bar ********************************************************************/
.nav-bar__container {
  grid-column: var(--nav-bar__container-columns);
  width: 100%;
  z-index: 100;
  background: var(--nav-bar__bg);
  border-radius: 1rem;
  margin-top: var(--nav-bar__container-margin-top);
}

.nav-bar__container-top {
  grid-row: 1 / 2;
}

.nav-bar__container-bottom {
  grid-row: 3 / 4;
}

.nav-bar__navigation {
  display: flex;
  justify-content: flex-start;
}

#nav-bar__back {
  /*flex: 1;
  flex-grow: 1;*/
}

#icon-previous-page {
    float: left;
    margin-left: 1rem;
}

.nav-bar__logo h1 {
  margin: .8rem;
  letter-spacing: .3rem;
}

#nav-bar__icon-container {
  flex: 3;
  flex-grow: 12;
  display: flex;
  justify-content: flex-end;
  padding-right: var(--nav-bar__icon-container-padding-right);
}

.nav-bar__icon {
  margin: 1rem .5rem;
  padding: 0;
  background-color: var(--icon-bg-color);
  border: 1px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.toggled {
  border: .2rem solid var(--club);
}

.nav-bar__icon-img {
  /*background-color: var(--icon-bg-color);*/
  vertical-align: middle;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.nav-bar__icon-img:hover,
.nav-bar__icon-img:focus {
  background-color: var(--bridge-active);
}

.nav-bar__icon:hover,
.nav-bar__icon:focus {
  background-color: var(--bridge-active);
  transition: .1s ease;
  border-radius: 0;
  outline: .2rem solid var(--club);
}

/* dialog *******************************************************************/
.dialog__title-bar {
  display: flex;
  color: var(--main-text-color);
  padding: .5rem;
  background: var(--dlg-title-bg);
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  font-family: sans-serif;
}

.dialog__title-bar h2 {
  flex-grow: 2;
  margin: 1rem 2rem;
}

.dialog__title-bar__icon-container {
  margin: .2rem;
  float: right;
}


.form-div {
  display: inline-block;
  margin: 1rem;
  width: 40%;
  vertical-align: top;
}

.tool-icon {
  margin: .5rem;
  display: inline-block;
}

/* modal **********************************************************************/
#exception-modal-content {
  color: var(--error-color);
  background-color: var(--error-background-color);
  padding: 2rem;
}

#export-deal-content {
  margin-left: 1rem;
  margin-right: 1rem;
  max-height: 50vh;
  overflow: scroll;
}

#import-deal-content {
  margin-left: 1rem;
  margin-right: 1rem;
}

#import-deal-content code {
  height: 260px;
}

#modal-container {
  display: none;
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-dialog {
  border-radius: 1rem;
  background-color: var(--modal-dialog-color);
  box-shadow: 0 19px 38px 12px var(--shadow1);
  margin: var(--modal-dialog-margin);
  width: var(--modal-dialog-width);
  height: fit-content;
}

.modal-dialog__content {
  background: var(--secondary-component-color);
  border: 1px solid var(--light-border-color);
  border-radius: 4px;
}

.char-icon {
  border: none;
  color: var(--spade);
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0 .5rem;
  padding: 0 .5rem;
}

.char-icon:hover,
.char-icon:focus {
  background-color: var(--bridge-active);
  color: #E60000;
  moz-border-radius: 50%;
  border-radius: 50%;
  transition: .5s ease;
  border: 1px;
  background-color: var(--bridge-active);
}


/* tabs ***********************************************************************/

.tab-button {
  box-sizing: border-box;
  width: 100%;
  margin: .2rem auto;
  color: var(--main-text-color);
  cursor: pointer;
  font-size: 80%;
  padding: .1rem 1rem 0;
  -webkit-border-top-left-radius: 3rem;
  -webkit-border-top-right-radius: 3rem;
  -moz-border-radius-topleft: 3rem;
  -moz-border-radius-topright: 3rem;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  border: .1rem solid var(--tab-button-border-color);
  border-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-button:focus,
.tab-button:hover {
  padding: 0 .6rem .5rem;
  background-color: var(--bridge-active);
  color: var(--memo-text-color);
  transition: .5s ease;
  -webkit-border-top-left-radius: 3rem;
  -webkit-border-top-right-radius: 3rem;
  -moz-border-radius-topleft: 3rem;
  -moz-border-radius-topright: 3rem;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  border: .5rem solid darkblue;
  border-bottom: 0;
  transition: 0.2s linear;
}

.tab-button.active {
  background-color: var(--memo-color);
  color: var(--memo-text-color);
}

.drop-down {
  position: relative;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.drop-down ul {
  background-color: var(--component-color);
  right: calc(-1 * var(--nav-bar__icon-container-padding-right));
  width: var(--drop-down__width);
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
  z-index: 101;
}

.drop-down li {
  margin-bottom: 0px;
  border-bottom: 1px solid var(--light-border-color);
}

.drop-down__button {
  height: 5rem;
  padding: 0 2rem;
}


/* deals menu *****************************************************************/

.deals-menu__container {
  margin: var(--deals-menu__container-margin);
  width: var(--deals-menu__container-width);
  height: var(--deals-menu__container-height);
  overflow: hidden;
  resize: both;
}

#deals-menu__folder-container {
  display: flex;
  height: 86%;
  margin: .5rem;
  border: 1px solid var(--component-container-color);
}

#deals-menu__folder-tabs {
  height: 100%;
  min-width: 30%;
  flex: 1 1 0;
  overflow-x: hidden;
  background-color: var(--component-color);
  margin-bottom: auto;
}

#deals-menu__folder-content {
  min-width: 30%;
  padding: 0 1rem;
  flex: 1 1 0;
  background-color: var(--component-color);
  overflow: hidden;
}

#deals-menu__folder-content ul {
  margin: 0;
  padding: 0;
}

#deals-menu__folder-content li {
  margin: 0;
}

.folder-content__button {
  color: var(--spade);
  width: 100%;
  margin: .1rem;
  border: none;
  cursor: pointer;
  font-size: 80%;
  padding: 0 .5rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder-content__button:hover {
  border-bottom: 6px solid darkblue;
}

.folder-content__button:active,
.folder-content__button:focus,
.folder-content__button:hover {
  background-color: var(--bridge-active);
  color: var(--memo-text-color);
  transition: 0.5s linear;
  padding: 0 .5rem;
  width: 100%;
}

#page-nav__container {
  padding: .2rem 0;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid var(--component-border-color);
}

#page-nav__container button {
  font-family: monospace, sans-serif;
  font-size: 2rem;
  width: 6rem;
  border: 1px solid #2e3ea3;
  color: #2e3ea3;
  margin: 0 .2rem;
  padding: .2rem;
  text-align: center;
}

#page-nav__container button:hover,
#page-nav__container button:focus {
  color: var(--highlight-text);
  background-color: var(--bridge-button__active);
  transition: .5s ease;
  width: 6rem;
  margin: 0 .2rem;
  padding: .2rem;
}

/** slider ********************************************************************/

.switch {
  position: relative;
  display: grid;
  margin: 0 1rem;
  width: 3.2rem;
  height: 1.8rem;
  top: .45rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.37rem;
  width: 1.37rem;
  left: .23rem;
  bottom: .23rem;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}


input:checked + .slider {
  background-color: #2196f3;
}

input:hover + .slider,
input:focus + .slider {
  box-shadow: 0 0 0 3px var(--club);
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.37rem);
  -ms-transform: translateX(1.37rem);
  transform: translateX(1.37rem);
}

.slider.round {
  border-radius: 1.37rem;
}

.slider.round:before {
  border-radius: 50%;
}


/***************************https://www.filamentgroup.com/lab/select-css.html**/
.select-css {
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: var(--main-text-color);
  line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 90%;
	max-width: 90%;
	box-sizing: border-box;
	margin: 1em;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: 2em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
  background-color: var(--secondary-component-color);
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: var(--spade);
}
.select-css:focus {
	border-color: var(--light-border-color);
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px var(--club);
	color: var(--main-text-color);
}
.select-css option {
	font-weight:normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
	background-position: left .7em top 50%, 0 0;
	padding: .6em .8em .5em 1.4em;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
	color: graytext;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}
.select-css:disabled:hover, .select-css[aria-disabled=true] {
	border-color: #aaa;
}

/* falling_suits***************************************************************/
.falling_suits {
    position:absolute ;
    overflow: hidden;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: -1;
}
.falling_suits div{
  position:absolute ;
  display:block ;
}
.falling_suits div:nth-child(1){
    font-size: 36rem;
    left:6%;
    animation:fall 28s linear infinite ;
    animation-delay:-2s;
}
.falling_suits div:nth-child(2){
    font-size: 66rem;
    left:74%;
    animation:fall 24s linear infinite ;
    animation-delay:-4s;
}
.falling_suits div:nth-child(3){
    font-size: 66rem;
    left:8%;
    animation:fall 20s linear infinite ;
    animation-delay:-61s;
}
.falling_suits div:nth-child(4){
   font-size: 6rem;
   left:50%;
   animation:fall 18s linear infinite ;
   animation-delay:-4s;
}
.falling_suits div:nth-child(5){
    font-size: 14rem;
    left:92%;
    animation:fall 14s linear infinite ;
    animation-delay:-18s;
}
.falling_suits div:nth-child(6){
  font-size: 24rem;
  left:18%;
  animation:fall 16s linear infinite ;
  animation-delay:-17s;
}
.falling_suits div:nth-child(7){
  font-size: 42rem;
  left:64%;
  animation:fall 22s linear infinite ;
  animation-delay:-6s;
}
.falling_suits div:nth-child(8){
  font-size: 18rem;
  left:14%;
  animation:fall 20s linear infinite ;
  animation-delay:-7s;
}
.falling_suits div:nth-child(9){
  font-size: 8rem;
   left:84%;
   animation:fall 28s linear infinite ;
   animation-delay:-18s;
}
.falling_suits div:nth-child(10){
    font-size: 12rem;
    left:64%;
    animation:fall 10s linear infinite ;
    animation-delay:-7s;
}
.falling_suits div:nth-child(11){
  font-size: 18rem;
    left:38%;
    animation:fall 16s linear infinite ;
    animation-delay:-8s;
}
.falling_suits div:nth-child(12){
  font-size: 46rem;
  left:0%;
  animation:fall 18s linear infinite ;
  animation-delay:-10s;
}
.falling_suits div:nth-child(13){
  font-size: 4rem;
   left:56%;
   animation:fall 22s linear infinite ;
   animation-delay:-8s;
}
.falling_suits div:nth-child(14){
    font-size: 16rem;
    left:82%;
    animation:fall 18s linear infinite ;
    animation-delay:-5s;
}
.falling_suits div:nth-child(15){
  font-size: 14rem;
    left:10%;
    animation:fall 16s linear infinite ;
    animation-delay:-10s;
}
.falling_suits div:nth-child(16){
  font-size: 26rem;
  left:76%;
  animation:fall 26s linear infinite ;
  animation-delay:-4s;
}

@keyframes fall {
    0%{
        opacity:.1;
        top:-40%;
        transform:translateX (20px);
    }
    20%{
        opacity:0.27;
        transform:translateX (-20px);
    }
    40%{
        opacity:0.55;
        transform:translateX (-20px);
    }
    60%{
       opacity:0.73;
       transform:translateX (-20px);
    }
    80%{
        opacity:0.96;
        transform:translateX (-20px);
    }
    100%{
        top:110%;
        transform:translateX (-20px);
    }
}
